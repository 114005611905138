<script setup>
import { useNuxtApp, onMounted, onBeforeUnmount } from '#imports';
import { useAuthStore } from '@/stores/auth';
import { usePolicePopup } from '@/composables/usePolicePopup';
import { police } from '@/configs/police';

import PopupCenter from '@/components/layout/popup/PopupCenter.vue';

const { $tp } = useNuxtApp();
const authStore = useAuthStore();
const { openPolicePopup } = usePolicePopup();

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  params: {
    type: Object,
    default: () => ({}),
  },
});

const actionsDictionary = {
  privacyPolicy: () => openPolicePopup(police.privacyPolicy),
};

function handleClick(event) {
  const element = event.target;
  const dataAction = element.dataset?.action;

  if (!dataAction) return;

  actionsDictionary?.[dataAction]?.();
}

onMounted(() => {
  document.addEventListener('click', handleClick);
});
onBeforeUnmount(() => {
  document.removeEventListener('click', handleClick);
});
</script>

<template>
  <PopupCenter full-width-mobile>
    <template #default>
      <div
        v-if="!authStore.isAuthorized"
        class="popup-police"
        v-html="$tp(props.content.noAuthorized)"
      />

      <div
        v-else
        class="popup-police"
        v-html="$tp(props.content.isAuthorized)"
      />
    </template>
  </PopupCenter>
</template>
<style scoped lang="scss">
.popup-police {
  $parent: &;

  display: flex;
  flex-direction: column;
  gap: em(36);
  width: em(800);
  padding: 0 em(64) em(36);
  margin-top: em(32);
  margin-bottom: em(32);
  background-color: $color-white;
  border-radius: em(48);

  &:deep(h2) {
    display: block;
    padding: em(36, 30) em(80, 30) em(36, 30) em(64, 30);
    margin-right: em(-64, 30);
    margin-left: em(-64, 30);
    font-size: em(30);
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: -0.03em;
    background-color: $color-grey-1;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    border-radius: em(48, 30);

    @include media-breakpoint-down(sm) {
      padding: em(32, 30) em(76, 30) em(32, 30) em(24, 30);
      margin-right: em(-24, 30);
      margin-left: em(-24, 30);
      line-height: 1;
      word-break: break-all;
      border-radius: em(32, 30);
    }
  }

  &:deep(h3) {
    font-size: em(20);
    font-weight: 600;
    line-height: 1.2;
  }

  &:deep(h4) {
    font-size: em(18);
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
  }

  &:deep(h5) {
    font-size: em(14);
    line-height: 1.2;
    text-transform: uppercase;
  }

  &:deep(p) {
    font-size: em(14);
    line-height: 1.2;

    @include media-breakpoint-down(md) {
      word-break: break-word;
    }
  }

  &:deep(strong) {
    font-weight: 600;
  }

  &:deep(sup) {
    position: relative;
    top: em(-10);
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  &:deep(a) {
    display: inline-block;
    font-weight: 600;
    color: $color-blue-1;
    text-decoration: none;

    @include hover {
      text-decoration: underline;
    }
  }

  &:deep(button) {
    display: inline-block;
    font-weight: 600;
    color: $color-blue-1;

    @include hover {
      text-decoration: underline;
    }
  }

  &:deep(time) {
    font-size: em(12);
    line-height: 1.3;
    letter-spacing: -0.03em;
  }

  &:deep(div) {
    display: flex;
    flex-direction: column;
    gap: em(16);
  }

  &:deep(ul),
  &:deep(ol) {
    display: flex;
    flex-direction: column;
    gap: em(16);
  }

  &:deep(ol) {
    counter-reset: ol-numbers;
  }

  &:deep(ol > li) {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: em(24);
    padding-left: em(25);

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      min-width: em(22, 14);
      font-size: em(14);
      text-align: right;
      content: counter(ol-numbers) '.';
      counter-increment: ol-numbers;
    }
  }

  &:deep(ul > li) {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: em(24);
    padding-left: em(25);

    &::before {
      position: absolute;
      top: em(8);
      left: em(8);
      width: em(4);
      height: em(4);
      content: '';
      background-color: $color-black;
      border-radius: 50%;
    }
  }

  &:deep(#{$parent}__children) {
    padding-left: em(25);
  }

  &:deep(#{$parent}__table) {
    gap: 0 !important;
    width: 100%;
  }

  &:deep(#{$parent}__table ul) {
    gap: 0;
  }

  &:deep(#{$parent}__table ul > li) {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: em(25);

    &::before {
      position: absolute;
      top: em(8);
      left: em(8);
      width: em(4);
      height: em(4);
      content: '';
      background-color: $color-black;
      border-radius: 50%;
    }
  }

  &:deep(#{$parent}__table-header) {
    display: flex;
    flex-direction: row;
    gap: 0;
    width: 100%;
    min-height: em(56);
    background: rgba($color-black, 0.06);
    border-top: 1px solid $color-grey-1;
  }

  &:deep(#{$parent}__table-cell) {
    flex-shrink: 0;
    gap: 0;
    padding: em(10) em(12);
    border-left: 1px solid $color-grey-1;

    &:last-child {
      border-right: 1px solid $color-grey-1;
    }

    &:nth-child(1) {
      width: 10%;
    }

    &:nth-child(2) {
      width: 24%;
    }

    &:nth-child(3) {
      width: 21%;
    }

    &:nth-child(4) {
      flex-grow: 1;
      flex-shrink: 1;
      width: auto;
    }
  }

  &:deep(#{$parent}__table-row) {
    display: flex;
    flex-direction: row;
    gap: 0;
    width: 100%;
    border-top: 1px solid $color-grey-1;

    &:last-child {
      border-bottom: 1px solid $color-grey-1;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 em(24) em(36);
    margin-top: em(24);
    margin-bottom: em(24);
  }
}
</style>
